import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
   {
      path: '/member/',
      component: () => import('@/layouts/link/Index.vue'),
      children: [
         {
            path: ':id/confirmation',
            name: 'Confirmation',
            component: () => import('@/views/members/Mobile.vue'), // Web Mobile
         },
         {
            path: ':id/correction',
            name: 'Correction',
            component: () => import('@/views/members/Mobile.vue'), // Web Mobile
         },
         {
            path: ':id/telegram',
            name: 'Telegram',
            component: () => import('@/views/members/Signup.vue'), // Web Mobile
         },
         {
            path: ':id/whatsapp',
            name: 'Whatsapp',
            component: () => import('@/views/members/Signup.vue'), // Web Mobile
         },
      ],
   },
   {
      path: '/visitor/',
      component: () => import('@/layouts/user/Index.vue'),
      children: [
         {
            path: '',
            name: 'Registration',
            component: () => import('@/views/visitors/Mobile.vue'), // Web Mobile
         },
         {
            path: ':email/invitation',
            name: 'Invitation',
            component: () => import('@/views/visitors/Mobile.vue'), // Web Mobile
         },
         {
            path: 'assistance',
            name: 'Assistance',
            component: () => import('@/views/visitors/Index.vue'),
         },
      ],
   },
   {
      path: '/tenant/',
      component: () => import('@/layouts/user/Index.vue'),
      children: [
         {
            path: '',
            name: 'Approval',
            component: () => import('@/views/tenants/Index.vue'),
         },
         /*
         {
            path: ':messaging',
            name: 'Enrollment',
            component: () => import('@/views/tenants/Mobile.vue'), // Web Mobile
         },
         */
         {
            path: ':id/telegram',
            name: 'Enrollment-Telegram',
            component: () => import('@/views/tenants/Mobile.vue'), // Web Mobile
         },
         {
            path: ':id/whatsapp',
            name: 'Enrollment-Whatsapp',
            component: () => import('@/views/tenants/Mobile.vue'), // Web Mobile
         },
      ],
   },
   {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
         {
            path: '',
            name: 'Home',
            component: () => import('@/views/home/Index.vue'),
         },
         {
            path: 'profile',
            name: 'Profile',
            component: () => import('@/views/profile/Index.vue'),
            meta: { requiresAuth: true },
         },
         {
            path: 'persons',
            name: 'Persons',
            component: () => import('@/views/persons/Index.vue'),
            meta: { requiresAuth: true },
         },
         {
            path: 'locations',
            name: 'Locations',
            component: () => import('@/views/locations/Index.vue'),
            meta: { requiresAuth: true },
         },
         {
            path: 'subjects',
            name: 'Subjects',
            component: () => import('@/views/subjects/Index.vue'),
            meta: { requiresAuth: true },
         },
         {
            path: 'activities',
            name: 'Activities',
            component: () => import('@/views/activities/Index.vue'),
            meta: { requiresAuth: true },
         },
         {
            path: 'settings',
            name: 'Settings',
            component: () => import('@/views/settings/Index.vue'),
            meta: { requiresAuth: true },
         },
         {
            path: 'docs/development',
            component: () => import('@/views/development/Index.vue'),
            meta: { requiresAuth: true },
            children: [
               {
                  path: ':title',
                  name: 'Development',
                  component: () => import('@/views/development/Index.vue'),
               },
            ],
         },
         {
            path: 'docs/operation',
            component: () => import('@/views/operation/Index.vue'),
            children: [
               {
                  path: ':title',
                  name: 'Operation',
                  component: () => import('@/views/operation/Index.vue'),
               },
            ],
         },
         {
            path: 'docs/programming',
            component: () => import('@/views/programming/Index.vue'),
            children: [
               {
                  path: ':title',
                  name: 'Programming',
                  component: () => import('@/views/programming/Index.vue'),
               },
            ],
         },
         {
            path: 'dashboard',
            name: 'Dashboard',
            component: () => import('@/views/dashboard/Index.vue'),
            meta: { requiresAuth: true },
         },
         {
            path: 'reports',
            name: 'Reports',
            component: () => import('@/views/reports/Index.vue'),
            meta: { requiresAuth: true },
         },
         {
            path: '*',
            name: 'FourOhFour',
            component: () => import('@/views/404/Index.vue'),
         },
      ],
   },
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   scrollBehavior: (to, from, savedPosition) => {
      if (to.hash) return { selector: to.hash }
      if (savedPosition) return savedPosition

      return { x: 0, y: 0 }
   },
   routes
})

router.beforeEach((to, from, next) => {
   if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isLoggedIn) next()
      else next('/')
   } else next()
})

export default router
