import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

Vue.use(Vuetify);

const vuetify = new Vuetify({
   theme: {
      options: {
         customProperties: true,
      },
      themes: {
         light: {
            primary: '#204165',
            secondary: '#424242',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107'
         },
      },
   },
   icons: {
      iconfont: 'mdi',
   },
});
Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: 'mdi' })

export default vuetify
