<template>
   <v-fade-transition mode="out-in">
      <router-view />
   </v-fade-transition>
</template>

<script>
export default {
   name: 'App',

   metaInfo: {
      title: 'App',
      titleTemplate: `%s | ${process.env.VUE_APP_CODE}`,
      htmlAttrs: { lang: 'en' },
      meta: [
         { charset: 'utf-8' },
         { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
   },

   created() {
      // console.log(`created ( navigator.userAgent )`, navigator.userAgent)
      let agent = navigator.userAgent
      let browser
      if (agent.match(/edg/i)) {
         browser = `Edge ${agent.split(/edg\//i)[1].split(' ')[0]}`
      } else if (agent.match(/chrome|chromium|crios/i)) {
         browser = `Chrome ${agent.split(/chrome\/|chromium\/|crios\//i)[1].split(' ')[0]}`
      } else if (agent.match(/safari/i)) {
         browser = `Safari ${agent.split(/safari\//i)[1].split(' ')[0]}`
      } else if (agent.match(/firefox|fxios/i)) {
         browser = `Firefox ${agent.split(/firefox\/|fxios\//i)[1].split(' ')[0]}`
      } else if (agent.match(/opr\//i)) {
         browser = `Opera ${agent.split(/opr\/\//i)[1].split(' ')[0]}`
      } else {
         browser = 'Other'
      }
      // console.log(`created ( browser = ${browser} )`)
      this.$store.commit('setBrowser', browser)
   },
};
</script>
