<template>
   <v-dialog :value="!!value.deviceId" :max-width="maxWidth" @click:outside="cameraEnded" @keydown.esc="cameraEnded">
      <v-card dark class="pa-4">
         <v-sheet color="black" height="474" style="justify-content: center; display: flex; overflow: hidden; border-radius: 0;">
            <video ref="video" :width="maxWidth" height="100%"></video>
         </v-sheet>
         <v-progress-circular v-if="!ready" width="4" :size="64" indeterminate color="grey lighten-1"
            style="position: absolute; top: calc(50% - 32px); left: calc(50% - 32px);" />
         <v-card-actions style="position: absolute; bottom: 20px; right: 20px;">
            <v-btn fab dark color="red darken-2" class="mx-4" @click="cameraEnded">
               <v-icon size="32">
                  mdi-close-thick
               </v-icon>
            </v-btn>
            <v-btn fab dark color="teal darken-2" @click="cameraImage">
               <v-icon size="32">
                  mdi-check-bold
               </v-icon>
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
// eslint-disable-next-line
import { jsonify } from '@/utils/nirtara'
export default {
   name: 'BaseDialogCam',

   props: {
      value: {
         type: Object,
         default: () => ({ type: '', deviceId: '', result: '' }),
      },
   },
   data: () => ({
      ready: false,
   }),
   computed: {
      maxWidth() {
         switch (this.value.type) {
            case 'Card': return 854;
            case 'Face': return 640;
            default: return 640;
         }
      },
   },

   watch: {
      'value.deviceId'(deviceId) {
         deviceId && this.cameraStart()
      },
   },
   created() {
   },
   mounted() {
   },
   methods: {
      cameraEnded() {
         const { video } = this
         if (video?.srcObject) {
            video.pause()
            video.srcObject.stop()
            video.srcObject = null
         }
         delete this.video
         this.value.deviceId = ''
         this.ready = false
      },
      cameraStart() {
         this.video && this.cameraEnded()
         const constraints = { deviceId: { exact: this.value.deviceId }, width: this.maxWidth, height: 480 }
         // console.log('cameraStart ( constraints )', jsonify(constraints))
         navigator.mediaDevices.getUserMedia({ video: constraints, audio: false }).then(stream => {
            const video = this.$refs.video
            video.srcObject = stream
            video.srcObject.stop = function () {
               this.getVideoTracks().forEach(function (track) { // in case... :)
                  track.stop()
               })
            }
            video.play()
            this.video = video
            this.ready = true
         })
      },
      cameraImage() {
         const { video } = this
         if (!video?.srcObject) return
         // console.log(`cameraImage ( ${video.videoWidth} x ${video.videoHeight} )`)
         const canvas = document.createElement('canvas')
         Object.assign(canvas, { width: video.videoWidth, height: video.videoHeight })
         canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, canvas.width, canvas.height)
         // var base64 = canvas.toDataURL('image/jpeg', 0.6)
         this.value.result = canvas.toDataURL('image/jpeg', 0.6)

         this.cameraEnded()
      },
   },
}
</script>