import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins'
import moment from 'moment'
import io from 'socket.io-client'
import excelJS from 'exceljs'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
// import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
// import '@mdi/font/css/materialdesignicons.css'

pdfMake.vfs = pdfFonts.pdfMake.vfs
excelJS.autoWidth = (worksheet, minimalWidth = 4) => {
   worksheet.columns.forEach(column => {
      let maxColumnLength = 0
      column.eachCell({ includeEmpty: true }, (cell) => {
         maxColumnLength = Math.max(maxColumnLength, minimalWidth, cell.value ? cell.value.toString().length : 0)
      })
      column.width = maxColumnLength + 4
   })
}
excelJS.numberToLetters = (number) => {
   let letters = ''
   while (number >= 0) {
      letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[number % 26] + letters
      number = Math.floor(number / 26) - 1
   }
   return letters
}

Vue.config.productionTip = false

moment.locale('id')
Vue.prototype.$moment = moment
Vue.prototype.$pdfMake = pdfMake
Vue.prototype.$excelJS = excelJS
Vue.prototype.$hasRole = (role, auth) => store.state.user[role.toLowerCase()] && auth.split('').some(a => store.state.user[role.toLowerCase()].indexOf(a) !== -1)

store.dispatch('settings').then(zone => {
   let id = (crypto.getRandomValues(new Uint32Array(1))[0] / 0x100000000).toString(16).padStart(7, '0').slice(-7).replaceAll('.', '0')
   id += (crypto.getRandomValues(new Uint32Array(1))[0] / 0x100000000).toString(16).padStart(8, '0').slice(-8).replaceAll('.', '0')
   Vue.prototype.$socket = io({ path: '/nirtara', query: { zone: zone, client: `browser_${id}` } })
})
Vue.prototype.$waitDomain = (callback) => {
   if (store.state.zone) callback(store.state.zone)
   else {
      const unwatch = store.watch(
         state => state.zone,
         zone => {
            if (!zone) return
            unwatch && unwatch()
            setTimeout(() => callback(zone), 1)
         },
         { immediate: true },
      )
   }
}

new Vue({
   router,
   store,
   vuetify,
   render: h => h(App)
}).$mount('#app')
