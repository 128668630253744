<template>
   <v-dialog v-model="value.del" :max-width="maxWidth" persistent>
      <v-card>
         <v-card-title style="line-height: 3.0rem;">Are you sure?</v-card-title>
         <v-card-subtitle style="line-height: normal;">{{ `This will delete ${record.name} permanently?` }}</v-card-subtitle>
         <v-divider />
         <v-card-text class="py-5 mx-auto" style="max-width: 200px">
            <v-otp-input v-model="otp" length="3" hide-details />
         </v-card-text>
         <v-divider />
         <v-card-actions class="py-3">
            <v-spacer />
            <v-btn text class="pa-5" @click="otp = ''; value.del = false">
               Cancel
            </v-btn>
            <v-btn color="secondary" depressed class="pa-5" :disabled="otp != $store.state.zone" @click="otp = ''; $emit('delete', record)">
               Delete
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
export default {
   name: 'BaseDialogDel',

   props: {
      value: {
         type: Object,
         default: () => ({ del: false }),
      },
      maxWidth: {
         type: [Number, String],
         default: 330,
      },
      record: {
         type: Object,
         default: () => ({ name: '' }),
      },
   },
   data: () => ({
      otp: ''
   }),
}
</script>