<template>
   <v-dialog :value="!!value.img" :max-width="maxWidth" @click:outside="value.img=''" @keydown.esc="value.img=''">
      <v-card class="pa-4">
         <v-img :src="value.img" alt="" contain />
      </v-card>
   </v-dialog>
</template>

<script>
export default {
   name: 'BaseDialogImg',

   props: {
      value: {
         type: Object,
         default: () => ({ img: '' }),
      },
      maxWidth: {
         type: [Number, String],
         default: 600,
      },
   },
}
</script>
